import React from "react"
import styled from "styled-components"

import TiktokIcon from "src/assets/svg/icon-tiktok.svg"
import FacebookIcon from "src/assets/svg/icon-facebook.svg"
import InstagramIcon from "src/assets/svg/icon-instagram.svg"
import TwitterIcon from "src/assets/svg/icon-twitter.svg"

import { useSelector } from "react-redux"
import { selectIsTranscendInitialized } from "src/modules/reducers/transcend"

const getCurrentYear = () => {
  return new Date().getFullYear()
}

const Footer = () => {
  const currentYear = getCurrentYear()

  // Transcend
  const isTranscendInitialized = useSelector(selectIsTranscendInitialized)
  const [showTranscendLink, setShowTranscendLink] = React.useState(false)

  const showTranscendHandler = e => {
    e.preventDefault()
    window.transcend.showConsentManager({
      viewState: "DoNotSellExplainer",
    })
  }

  const hideTranscendHandler = React.useCallback(
    e => {
      if (showTranscendLink && e.key === "Escape") {
        window.transcend.hideConsentManager()
      }
    },
    [showTranscendLink]
  )

  React.useEffect(() => {
    document.addEventListener("keydown", hideTranscendHandler)
    return () => document.removeEventListener("keydown", hideTranscendHandler)
  }, [hideTranscendHandler])

  React.useEffect(() => {
    if (isTranscendInitialized) {
      setShowTranscendLink(
        window.transcend?.ready &&
          window.airgap?.getRegimes &&
          window.airgap.getRegimes().has("DoNotSell")
      )
    }
  }, [isTranscendInitialized])

  return (
    <StyledFooter>
      <FooterTopLinks>
        <FooterNav>
          <a className="footer-link" href="mailto:cflately@fubo.tv">
            Advertise
          </a>
          <a className="footer-link" href="mailto:press@fubo.tv">
            Press Inquiries
          </a>
          <a
            className="footer-link"
            href="https://www.fubo.tv/news"
            target="_blank"
            rel="noopener noreferrer"
          >
            News
          </a>
        </FooterNav>
        <FooterSocialLinks>
          <a
            href="https://www.tiktok.com/@fubosports"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TiktokIcon />
          </a>
          <a
            href="https://www.facebook.com/fubosports"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.twitter.com/fubosports"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://www.instagram.com/fubosports"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
        </FooterSocialLinks>
      </FooterTopLinks>

      <FooterMiddle>
        <FooterBlurb>
          Available on more than 155 million devices, Fubo Sports is the live,
          free-to-consumer TV network featuring sports stories on and off the
          field. Launched by live TV streaming platform FuboTV (NYSE: FUBO) in
          September 2019, Fubo Sports airs live sports, movies and
          documentaries, award-winning original programming and partner content.
          Produced by Fubo Studios with a mission to bring the voice of the
          athlete to fans, Fubo Sports’ original programming is hosted by iconic
          athletes and current stars.
        </FooterBlurb>

        {/* <FooterSupportLinks>
                        <a href="tel:+18445511005"><span>Call Us</span><span>844-551-1005</span></a>
                        <a href="https://support.fubo.tv/hc/requests/new" target="_blank" rel="noopener noreferrer">Email Us</a>
                    </FooterSupportLinks> */}
      </FooterMiddle>

      <FooterBottom>
        <FooterCopyright>© {currentYear} fuboTV Inc.</FooterCopyright>
        <FooterPrivacy>
          {showTranscendLink && (
            <a className="footer-link" href="" onClick={showTranscendHandler}>
              Your Privacy Choices
            </a>
          )}
          <a
            className="footer-link"
            href="https://www.fubo.tv/documents/USA/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </a>

          <a
            className="footer-link"
            href="https://legal.fubo.tv/policies/en-US/?name=unsolicited-submissions-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Unsolicited Submissions
          </a>
          <a
            className="footer-link"
            href="https://www.fubo.tv/documents/USA/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
          <a
            className="footer-link"
            href="https://www.fubo.tv/documents/USA/cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie Policy
          </a>
        </FooterPrivacy>
      </FooterBottom>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.footer`
  font-size: 14px;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  background: #242424;
  font-family: "Qanelas-Bold";
  font-weight: 800;

  a {
    color: #fff;
  }

  @media (min-width: 576px) {
    justify-content: flex-end;
  }
`

const FooterTopLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    margin-bottom: 20px;
    align-items: center;
  }

  a {
    text-decoration: none;
    margin: 0 5px;

    :first-child {
      margin-left: 0;
    }
  }
`
const FooterNav = styled.nav`
  font-size: 14px;
  font-weight: 800;
  line-height: 2;
  white-space: nowrap;
`
const FooterSocialLinks = styled.div`
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
  a {
    font-size: 14px;
    font-weight: 800;
    line-height: 2;
    white-space: nowrap;
  }
  svg {
    fill: #fff;
    opacity: 0.5;
  }
`
const FooterMiddle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`
const FooterBlurb = styled.div`
  max-width: 643px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-family: Qanelas-Medium;
  font-weight: 500;
  letter-spacing: 0.13px;
  line-height: 21px;
  opacity: 0.6;

  @media (max-width: 600px) {
    max-width: 580px;
    text-align: center;
  }
`

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`
const FooterCopyright = styled.div`
  font-family: "Qanelas-Regular";
  opacity: 0.6;
  color: #fff;

  @media (max-width: 600px) {
    margin-top: 10px;
  }
`
const FooterPrivacy = styled.div`
  font-size: 14px;
  font-weight: 800;
  line-height: 2;
  white-space: nowrap;
  opacity: 0.6;
  display: flex;
  gap: 12px;

  a {
    ${"" /* margin-right: 13px; */}
    text-decoration: none;
  }
`
